@tailwind base;
@tailwind components;
@tailwind utilities;

/* @font-face {
  font-family: "Sohne-Bold";
  src: url("./assets/fonts/Söhne-Dreiviertelfett.otf") format("opentype");
}

@font-face {
  font-family: "Sohne-SemiBold";
  src: url("./assets/fonts/Söhne-Halbfett.otf") format("opentype");
}

@font-face {
  font-family: "Sohne-Regular";
  src: url("./assets/fonts/Söhne-Kräftig.otf") format("opentype");
}

@font-face {
  font-family: "Sohne-Light";
  src: url("./assets/fonts/Söhne-Leicht.otf") format("opentype");
} */

@font-face {
  font-family: "shnebold";
  src: url("./assets/webfontkit/sohne-dreiviertelfett-webfont.woff2")
      format("woff2"),
    url("./assets/webfontkit/sohne-dreiviertelfett-webfont.woff") format("woff");
  /* font-weight: normal;
  font-style: normal; */
}

@font-face {
  font-family: "shnehalbfett";
  src: url("./assets/webfontkit/sohne-halbfett-webfont.woff2") format("woff2"),
    url("./assets/webfontkit/sohne-halbfett-webfont.woff") format("woff");
  /* font-weight: normal;
  font-style: normal; */
}

@font-face {
  font-family: "shnekrftig";
  src: url("./assets/webfontkit/sohne-kraftig-webfont.woff2") format("woff2"),
    url("./assets/webfontkit/sohne-kraftig-webfont.woff") format("woff");
  /* font-weight: normal;
  font-style: normal; */
}

@font-face {
  font-family: "shneleicht";
  src: url("./assets/webfontkit/sohne-leicht-webfont.woff2") format("woff2"),
    url("./assets/webfontkit/sohne-leicht-webfont.woff") format("woff");
  /* font-weight: normal;
  font-style: normal; */
}

/* .ant-float-btn-body{
  background-color: ;
}

.ant-float-btn-content{
  background-color: brown;
} */

.popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 999;
  background: white;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  width: 35%;
}

@media screen and (max-width: 768px) {
  iframe {
    width: 90%; /* Set to full width on smaller screens */
  }
  .popup {
    width: 90%;
  }
}

.button-container {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
.popup-button {
  width: 50%;
  margin-top: 1rem;
  background-color: #a2cc49;
  color: #ffffff;
  padding: 10px;
  border-radius: 10px;
}
