@import "../../../variables.scss";

// .new-modal {
//   top: 30px !important;
//   height: fit-content !important;
//   position: relative !important;
//   width: fit-content !important;

//   @include breakpoint(phablet) {
//     height: fit-content !important;
//   }

//   @media screen and (min-width: 600px) and (max-width: 800px) and (orientation: landscape) {
//     height: 90vh !important;
//   }

//   .ant-modal-content {
//     // background-color: transparent;

//     @media screen and (min-width: 600px) and (max-width: 800px) and (orientation: landscape) {
//       height: 90vh !important;
//     }
//   }
//   .ant-modal-close {
//     width: 100%;
//     border-radius: 100%;
//     position: absolute;
//     top: -40px;
//     z-index: 10 !important;
//     text-align: center;
//     @include rowflex(center, center);

//     @include breakpoint(desktop) {
//       width: fit-content;
//       @include rowflexx(flex-start);
//     }
//   }
//   .ant-modal-body {
//     background-color: white;
//     border-radius: 12px;
//     .backcontinue.--bctop {
//       margin-top: 0px;
//       @include breakpoint(mobileonly) {
//         margin-top: 80px;
//       }
//       .backcontinue {
//         @include breakpoint(mobileonly) {
//           background-color: white;
//           position: absolute;
//           bottom: 20px;
//           margin: 0 auto;
//           width: 90%;
//           height: 120px;
//           left: 0px;
//           right: 0px;
//           button {
//             margin: 0 auto;
//             margin-left: 10px;
//             width: 90%;
//           }
//         }
//       }
//     }
//     // top: 150px !important;

//     @include breakpoint(mobileonly) {
//       max-height: 70vh !important;
//       overflow-y: scroll;
//       overflow-x: hidden;
//       @include overflowScroll($vesti-200, $vesti-800);
//     }
//   }
//   .ant-modal-close-x {
//     position: absolute;
//     //    right: 50%;
//     background-color: $vesti-400 !important;
//     border: 2px solid $vesti-902;
//     @include rowflex(center, center);
//     width: 60px;
//     height: 60px;
//     border-radius: 100%;
//   }
// }

.new-modal {
  top: 30px !important;
  height: fit-content !important;
  position: relative !important;
  // width: 100% !important;
  // width: fit-content !important;

  @include breakpoint(phablet) {
    height: fit-content !important;
  }

  // @media screen and (min-width: 600px) and (max-width: 800px) and (orientation: landscape) {
  //   height: 90vh !important;
  // }

  // .ant-modal-content {
  //   // background-color: transparent;
  //   padding: 5px;
  //   // @media screen and (min-width: 600px) and (max-width: 800px) and (orientation: landscape) {
  //   //   height: 90vh !important;
  //   // }
  // }
  .ant-modal-close {
    width: 100%;
    border-radius: 100%;
    position: absolute;
    top: -24px;
    z-index: 10 !important;
    text-align: center;
    @include rowflex(center, center);

    @include breakpoint(mobileonly) {
      width: 10%;
      @include rowflexx(flex-start);
    }
    @include breakpoint(phablet) {
      width: 6%;
      @include rowflexx(flex-start);
    }

    @include breakpoint(desktop) {
      width: fit-content;
      @include rowflexx(flex-start);
    }
  }
  .ant-modal-body {
    background-color: white;
    border-radius: 12px;
    width: 100%;
    .backcontinue.--bctop {
      margin-top: 0px;
      @include breakpoint(mobileonly) {
        margin-top: 80px;
        position: relative;
      }
      .backcontinue {
        @include breakpoint(mobileonly) {
          background-color: white;
          position: absolute;
          bottom: 20px;
          margin: 0 auto;
          width: 100%;
          height: 120px;
          left: 0px;
          right: 0px;
          button {
            margin: 0 auto;
            // margin-left: 10px;
            width: 100%;
          }
        }
      }
    }

    @include breakpoint(mobileonly) {
      // height: 90vh !important;
      overflow-y: scroll;
      overflow-x: hidden;
      @include overflowScroll($vesti-200, $vesti-800);
      padding: 10px !important;
    }
  }
  .ant-modal-close-x {
    position: absolute;
    //    right: 50%;
    background-color: $vesti-400 !important;
    border: 2px solid $vesti-902;
    @include rowflex(center, center);
    width: 60px;
    height: 60px;
    border-radius: 100%;
  }
}

.element-modal {
  top: 30px !important;
  height: fit-content !important;
  position: relative !important;

  @include breakpoint(phablet) {
    height: fit-content !important;
  }

  @media screen and (min-width: 600px) and (max-width: 800px) and (orientation: landscape) {
    height: 90vh !important;
  }

  .ant-modal-content {
    // background-color: transparent;

    @media screen and (min-width: 600px) and (max-width: 800px) and (orientation: landscape) {
      height: 90vh !important;
    }
  }
  .ant-modal-close {
    width: 100%;
    border-radius: 100%;
    position: absolute;
    top: -40px;
    color: white;
    font-weight: 600;
    z-index: 10 !important;
    text-align: center;
    @include rowflex(center, center);

    @include breakpoint(desktop) {
      width: fit-content;
      @include rowflexx(flex-start);
    }
  }
  .ant-modal-body {
    background-color: white;
    border-radius: 12px;
    .backcontinue.--bctop {
      margin-top: 0px;
      @include breakpoint(mobileonly) {
        margin-top: 80px;
      }
      .backcontinue {
        @include breakpoint(mobileonly) {
          background-color: white;
          position: absolute;
          bottom: 20px;
          margin: 0 auto;
          width: 90%;
          height: 120px;
          left: 0px;
          right: 0px;
          button {
            margin: 0 auto;
            margin-left: 10px;
            width: 90%;
          }
        }
      }
    }
    // top: 150px !important;

    @include breakpoint(mobileonly) {
      max-height: 70vh !important;
      overflow-y: scroll;
      overflow-x: hidden;
      @include overflowScroll($vesti-200, $vesti-800);
    }
  }
  .ant-modal-close-x {
    position: absolute;
    background-color: #d7a7f9 !important;
    border: 1px solid rgb(49, 0, 87, 0.2);
    color: #fff;
    @include rowflex(center, center);
    width: 60px;
    height: 60px;
    border-radius: 100%;
  }
}

/////////
///
.notification-modal {
  top: 30px !important;
  height: fit-content !important;
  position: relative !important;
  width: fit-content !important;

  @include breakpoint(phablet) {
    height: fit-content !important;
  }

  @media screen and (min-width: 600px) and (max-width: 800px) and (orientation: landscape) {
    height: 90vh !important;
  }

  .ant-modal-content {
    // background-color: transparent;

    @media screen and (min-width: 600px) and (max-width: 800px) and (orientation: landscape) {
      height: 90vh !important;
    }
  }
  .ant-modal-close {
    position: absolute;
    top: 10px;
    right: 10px;
    // width: 100%;
    // border-radius: 100%;
    // @include breakpoint(desktop) {
    //   width: fit-content;
    //   @include rowflexx(flex-start);
    // }
  }
  .ant-modal-body {
    background-color: white;
    border-radius: 12px;
    margin-top: 30px;
    // top: 150px !important;

    @include breakpoint(mobileonly) {
      max-height: 70vh !important;
      overflow-y: scroll;
      overflow-x: hidden;
      @include overflowScroll($vesti-200, $vesti-800);
    }
  }
  .ant-modal-close-x {
    // position: absolute;
    //    right: 50%;
    // background-color: $vesti-400 !important;
    // @include rowflex(center, center);
    // width: 60px;
    // height: 60px;
  }
}
