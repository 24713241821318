@import "../../variables.scss";

.about-container {
  width: 100%;
  margin-top: 5rem;
  @include colflex(center, center);

  @include breakpoint(mobileonly) {
    margin-top: 0rem;
    margin-bottom: 10rem;
  }

  .about-inner {
    width: 90%;
    @include rowflex(center, center);

    @include breakpoint(mobileonly) {
      width: 80%;
      @include colflex(center, center);
    }
    @include breakpoint(phablet) {
      width: 90%;
      @include rowflex(center, center);
      gap: 3rem;
    }
    @include breakpoint(tablet) {
      width: 90%;
      @include rowflex(center, center);
      gap: 3rem;
    }
    // @include breakpoint(desktop){
    //     width: 90% ;
    //     @include rowflex(center, center);
    //     // gap: 3rem;
    // }

    .__left {
      width: 100%;

      img {
        @include breakpoint(desktop) {
          width: 80%;
        }
      }
    }
    .__right {
      width: 75%;
      @include breakpoint(mobileonly) {
        width: 100%;
      }
      @include breakpoint(phablet) {
        width: 100%;
        gap: 3rem;
        @include colflex(flex-start, center);
      }
      @include breakpoint(tablet) {
        width: 100%;
        @include colflex(flex-start, center);
      }
      @include breakpoint(laptop) {
        width: 100%;
        gap: 6rem;
        @include colflex(flex-start, center);
      }
      .__title {
        @include breakpoint(mobileonly) {
          margin-top: 2rem;
        }

        p:nth-child(1) {
          @include fontBold(2em, 1.5em, $vesti-901);

          @include breakpoint(mobileonly) {
            text-align: center;
            @include fontBold(1.3em, 1.5em, $vesti-901);
          }

          @include breakpoint(phablet) {
            @include fontBold(1.2em, 1.3em, $vesti-901);
          }
          @include breakpoint(tablet) {
            @include fontBold(1.5em, 1.3em, $vesti-901);
          }
        }
        span {
          color: $vesti-700;
        }
      }

      .__body {
        margin-top: 5rem;
        margin-bottom: 5rem;

        @include breakpoint(mobileonly) {
          text-align: center;
          margin-top: 0rem;
          margin-bottom: 3rem;
        }
        @include breakpoint(phablet) {
          margin-top: 0rem;
          margin-bottom: 0rem;
        }
        @include breakpoint(tablet) {
          margin-top: 0rem;
          margin-bottom: 0rem;
        }
        @include breakpoint(laptop) {
          margin-top: 3rem;
          margin-bottom: 0rem;
        }
        p {
          @include fontNormal(1.35em, 1.5em, $vesti-901);

          @include breakpoint(mobileonly) {
            @include fontNormal(1em, 1.3em, $vesti-901);
          }
          @include breakpoint(phablet) {
            @include fontNormal(1em, 1.3em, $vesti-901);
          }
          @include breakpoint(tablet) {
            @include fontNormal(1.3em, 1.5em, $vesti-901);
          }
        }
      }

      .__button {
        width: 100%;
        @include breakpoint(mobileonly) {
          @include rowflex(center, center);
        }
        @include breakpoint(phablet) {
          @include rowflex(center, flex-start);
        }
        button {
          width: 50% !important;
          height: 60px !important;
          background-color: #67a948;
          transition: all 0.5s ease;
          @include fontMedium(1em, 1em, $gray-100);
          cursor: pointer;

          @include breakpoint(tablet) {
            @include fontMedium(1.3em, 1em, $gray-100);
          }
          @include breakpoint(laptop) {
            @include fontMedium(1.2em, 1em, $gray-100);
          }
          // @include breakpoint(desktop){
          //     width: 100% !important;
          //     height: 120px !important;
          //     @include fontMedium(2.5em, 1em, $gray-100);
          // }
        }
      }
    }
  }
}

@media (min-width: 2560px) {
  .about-container {
    width: 100%;
    margin-top: 5rem;
    @include colflex(center, center);

    .about-inner {
      width: 90%;
      @include rowflex(center, center);

      .__left {
        width: 100%;

        img {
          width: 80%;
        }
      }
      .__right {
        width: 100%;
        @include colflex(flex-start, center);
        .__title {
          p:nth-child(1) {
            @include fontBold(4.5em, 1.3em, $vesti-901);
          }
        }

        .__body {
          margin-top: 0rem;
          margin-bottom: 0rem;
          p {
            @include fontNormal(3.1em, 1.5em, $vesti-901);
          }
        }

        .__button {
          button {
            width: 100% !important;
            height: 120px !important;
            @include fontMedium(2.5em, 1em, $gray-100);
          }
        }
      }
    }
  }
}

.ostepone {
  // max-width: 450px;
  width: 100%;
  @include colflexx(flex-start);
  gap: 40px;

  &__row {
    width: 100% !important;
    @include colflexx(flex-start);
    gap: 20px;
    @include breakpoint(desktop) {
      @include rowflexx(flex-start);
    }
    *.PhoneInput {
      width: 100%;
    }
  }
  *.PhoneInput {
    width: 100%;
    @include breakpoint(mobileonly) {
      width: 75vw !important;
    }
  }

  &__col,
  &__scol,
  &__bcol {
    width: 100%;
    gap: 20px;
    @include colflexx(flex-start);
  }
  .inputfloat {
    margin-top: 0px;
    width: 100%;

    @include breakpoint(mobileonly) {
      width: 75vw !important;
    }
  }
  &__scol {
    gap: 15px;
  }
  &__bcol {
    gap: 20px;
  }
  &__btns {
    width: 100%;
  }
  &__form {
    max-height: 500px;
    @include overflowScroll($vesti-100, $vesti-800);
  }
}
